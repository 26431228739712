import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// firebase package imports
import { getAuth } from "firebase/auth";

// project imports
import useAuth from "hooks/useAuth";

import { LOGIN } from "store/actions";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * ログイン、サインアップ以外のルーティングに対しての認証ガード
 * @param {PropTypes.node} children children element/node
 */

const AuthGuard = ({ children }) => {
  const { isLoggedIn, user, param, dispatch, token } = useAuth();
  const auth = getAuth();
  const location = useLocation();
  const navigate = useNavigate();
  console.log("AuthGuard navigate", navigate);

  console.log("AuthGuard", location);
  console.log("AuthGuard isLoggedIn", isLoggedIn);
  console.log("AuthGuard param", param);
  console.log("AuthGuard user", user);
  console.log("AuthGuard token", token);

  const url = new URL(window.location.href);
  const qChannelId = url.searchParams.get("channelId");
  // checkin用
  const qStoreId = url.searchParams.get("id");
  const qShopName = url.searchParams.get("name");
  const qType = url.searchParams.get("type");
  const qContentsId = url.searchParams.get("contents_id");
  console.log("index.jsでのURLパス", url);
  console.log("クエリパラメータ", qChannelId, qStoreId);

  useEffect(() => {
    (async () => {
      if (
        (!isLoggedIn &&
          !location?.state?.findoutUserId &&
          //findoutUser登録必須ルートをここに定義する
          url.pathname !== "/providerprofile",
        url.pathname !== "/checkin",
        url.pathname !== "/checkinqr")
      ) {
        console.log(
          "start AuthGuard navigate locationpath(指定ルート以外の場合)"
        );
        let accessToken;

        if (!token) {
          console.log("firebase Auth未認証の為、ログイン画面へリダイレクト");
          navigate("/login", {
            state: {
              qChannelId: qChannelId,
              qStoreId: qStoreId,
              qShopName: qShopName,
              qContentsId: qContentsId,
              qType: qType,
              urlPath: url.pathname,
            },
            replace: true,
          });
        } else {
          console.log("firebase Auth認証済みの為");
          dispatch({
            type: LOGIN,
            payload: {
              user: {
                uuid: user?.uuid,
                providerId: user?.providerId,
                providerType: user?.providerType,
                providerUserId: user?.providerUserId,
                providerGroup: user?.providerGroup,
                email: user?.email,
                chData: user?.chData,
                userData: user?.userData,
              },
              token: token || accessToken,
              url: location?.search,
            },
          });
        }

        /////////
        // findoutユーザが未作成
      } else if (!isLoggedIn && !location?.state?.findoutUserId) {
        console.log("start AuthGuard navigate login");
        navigate("login", {
          state: {
            qChannelId: qChannelId,
            qStoreId: qStoreId,
            qContentsId: qContentsId,
            urlPath: url.pathname,
          },
          replace: true,
        });
        //findoutユーザ、providerユーザどちらも作成済み
      } else if (isLoggedIn && location?.state?.findoutUserId) {
        console.log("start AuthGuard navigate locationpath");
        navigate(location.pathname, {
          state: {
            qChannelId: qChannelId,
            qStoreId: qStoreId,
            qContentsId: qContentsId,
            urlPath: url.pathname,
          },
          replace: true,
        });
      }
    })();

    console.log("end AuthGuard useEffect");
  }, [isLoggedIn, navigate]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
