// action - state management
import {
  AUTHENTICATED,
  LOGIN,
  LOGINFAILED,
  LOGOUT,
  REGISTER,
  USERUPDATE,
} from "./actions";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER: {
      console.log("start accountReducer REGISTER");
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    case AUTHENTICATED: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: false,
        isInitialized: true,
        user,
      };
    }
    case LOGIN: {
      console.log("start accountReducer LOGIN");
      const { user, token, url } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
        token,
        url,
      };
    }
    case LOGOUT: {
      console.log("start accountReducer LOGOUT");
      const { param, url, token } = action.payload;

      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
        param,
        url,
        token,
      };
    }
    case LOGINFAILED: {
      const { param, url, token, errorCode } = action.payload;

      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
        param,
        url,
        token,
        errorCode,
      };
    }
    case USERUPDATE: {
      const { user } = action.payload;
      console.log("accountReducer", user);
      return {
        ...state,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
